import * as React from "react"
import { LoginForm } from "../components"

const Index = () => (
  <>
    <LoginForm />
  </>
)

export default Index
